@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/OpenSans-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/OpenSans-Bold.ttf');
  font-weight: 700;
  font-style: bold;
}

body {
  background: #f2f2f2;
}

.h-100-minus {
  height: calc(100vh - 56px);
}

.h-100{
  height: 100vh !important;
}

.no-decoration {
  border: none;
  background: transparent;
}

canvas {
  width: 100% !important;
  height: 250px !important;
}

.pdf-font {
  font-family: 'OpenSans' !important;
}

fieldset {
  display: inline;
}

.pointer {
  cursor: pointer;
}

.signature {
  max-width: 500px;
  max-height: 100px;
}

.flex-gap-3 {
  margin-bottom: 0;
}

.border-black {
  border-color: #000000 !important;
}

@media (max-width: 500px) {
  .signature {
    max-width: 250px;
    max-height: 100px;
  }

  .flex-gap-3 {
    justify-content: center !important;
  }

  .h-100-minus {
    margin-top: 30px;
  }
}

.component {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 4rem;
  height: 4rem;
  border: 3px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.5);
  border-bottom-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  animation: rotation 0.8s ease infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner-container {
  width: 4rem;
  height: 4rem;
}

.gap {
  gap: 10px;
}

.name-title {
  width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}